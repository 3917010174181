<template>
    <div class="w3-theme-l1" style="">
        <div class="w3-container" style="padding-top:10px">
            <Toolbar  
                :click-add="showAdd"
                :click-refresh="loadData"
                :click-print="showPrint"
                :set-filter="setFilter"
                :field-find="filters.fields"
                :data   = "recs"
                :fields = "json_fields"
                type    = "xls"
                name    = "info.xls"
                help = ""
            />
            <div @mouseover="grid = true" @mouseleave="grid = false">
                <vue-scrolling-table v-bind:style="'height:'+  (screenHeight-149) +'px;'" :class="'w3-table w3-striped w3-bordered w3-white w3-small freezeFirstColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="false" id="grid">
                    <template slot="thead">
                        <tr class="w3-border-right">
                            <th style="min-width:50px;" class="w3-light-grey w3-border-0">
                                No
                            </th>
                            <th v-for="fl in filterByShow" :key="fl.key" :style="'min-width:' + fl.width+
                            (fl['sort']['status']?';cursor:pointer':'')"
                                class="w3-light-grey w3-border-0" >
                                <div v-on:click="setFilterTable(fl)">{{fl.name}}
                                    <span v-if="fl['sort']['status']">
                                        <i class="fa"
                                            :class="fl['sort']['asc']=='asc'?'fa-sort-asc':(fl['sort']['asc']=='desc'?'fa-sort-desc':'fa-arrows-v')"></i>
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </template>
                    <template slot="tbody">
                        <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in recs"  style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == selectedRow ?'bayangan w3-theme-l4':'w3-opacity-min'))"  :key="dtl.info_code" v-on:dblclick="showEdit(dtl, index);" class="w3-text-black">
                            <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>{{index+((pages.halaman-1)*pages.batas)+1}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','info_jenis')" class=" w3-border-0 w3-border-right">
                                {{getJenis(dtl.info_jenis)}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','info_judul')" class="w3-border-0 w3-border-right">
                                <span v-html="setTextFind(dtl.info_judul,'info_judul')"></span>
                            </td>
                            <!-- <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','info_isi')" class=" w3-border-0 w3-border-right">
                                <span v-html="setTextFind(dtl.info_isi,'info_isi')"></span>
                            </td> -->
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','date_edit')" class=" w3-border-0 w3-border-right">
                                {{dtl.date_edit | moment("DD-MM-yyyy")}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','user_edit')" class=" w3-border-0 w3-border-right">
                                <span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
                            </td>
                            <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','info_status')" class=" w3-border-0 w3-center w3-border-right w3-border-right" >
                                <div v-on:click="setKlikRow(index, dtl)" style="position: relative;" :style="'min-width:' + findObjFind('width','info_status')">
                                    <span v-html="setStatus(dtl.info_status)"></span>
                                    <div v-show="dtl['pilih']==true && dtl.info_status=='T'" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template slot="tfoot">
                        <tr style="display: block;">
                            <td style="padding: 0px;" :style="'display: contents;'">
                                <Paging class="w3-center"
                                    :click-handler="loadPage"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'w3-bar'"
                                    :page-count="pages.ttlhalaman"
                                    :page-range="10"
                                    :margin-pages="2"
                                    :value="pages.halaman">
                                </Paging>
                            </td>
                        </tr>
                    </template>
                </vue-scrolling-table>
            </div>
        </div>
        <!-- Modal -->
        <div id="id01" class="w3-modal" >
            <vue-draggable-resizable :y="50" :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:90%;">   
                    <header class="w3-container w3-theme-d3 drag" style="cursor:move;"> 
                        <span onclick="document.getElementById('id01').style.display='none'" 
                        class="w3-button w3-display-topright">&times;</span>
                        <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                    </header>
                    <div class="w3-container w3-padding w3-theme-l4">
                        <form method="post" id="frmBarang" v-on:submit.prevent="saveData" action="#" autocomplete="off">
							<div class="w3-row" style="margin-top:5px;">
								<div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
									<label for="info_jenis">{{findObjFind('name','info_jenis')}} :</label>
								</div>
								<div class="w3-col l10 m9 s12">
									<select id="info_jenis" v-model="rec.info_jenis" class="w3-select w3-small w3-white">
										<option v-for="(dtl, index) in filters.fields[1]['valueFind']" :value="dtl.key" :key="index">{{dtl.label}}</option>
									</select>
								</div>
							</div>
                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="info_judul">{{findObjFind('name','info_judul')}} :</label>
                                </div>
                                <div class="w3-col l10 m6 s12">
                                    <input type="text" id="info_judul" v-model="rec.info_judul" class="w3-input w3-small w3-round w3-border" :placeholder="findObjFind('name','info_judul')" />
                                </div>
							</div>
							<div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="info_isi">{{findObjFind('name','info_isi')}} :</label>
                                </div>
                                <div class="w3-col l10 m9 s12">
									<v-editor v-model="rec.info_isi" :config="editorConfig"></v-editor>
                                </div>
                            </div>
							

                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="info_status">&nbsp;</label>
                                </div>
                                <div class="w3-col l4 m9 s12">
                                    <label class="container w3-small">{{findObjFind('name','info_status')}}
                                        <input v-model="rec.info_status" type="checkbox" true-value="T" false-value="F">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <footer class="w3-container w3-theme-l1">
                        <label class="container w3-small w3-left" style="margin-top: 15px;">Auto add
                            <input true-value="T" false-value="F" v-model="pages.autoadd" type="checkbox" checked="checked">
                            <span class="checkmark w3-round"></span>
                        </label>
                        <h6 class="w3-right">
                            <button form="frmBarang" type="submit" class="w3-btn w3-small w3-theme-d5 w3-round" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                            <button onclick="document.getElementById('id01').style.display='none'" class="w3-btn w3-small w3-red w3-round"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </footer>
                </div>
            </vue-draggable-resizable>
        </div>       
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
export default {
    head: {
        title: {
          inner: 'info'
        },
    },
    name: 'layanan',
    components: {
		'v-editor': YimoVueEditor.instance
	},
    data() {
        return {
            err:'',
            grid : false,
            screenWidth : 0,
            screenHeight : 0,
            tableHeight : 0,
            selectedRow : 0,
            errorMessage : {info_isi:'',info_judul:''},
            fieldRules : [],
            recs : [],
            rec : {info_code:'', info_isi : '', info_judul:'', info_status:'T'},
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, autoadd :'F'},
            filters : {
                operator : 'AND',
                fields: [
                    {fieldFind:'', key : 'info_code', type: 'text', name : 'Kode', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'info_jenis', type: 'select', name : 'Jenis', filter : true, width : '150px', grid : true, valueFind: [{key:'A',label:'Jam Pelayanan'},{key:'B',label:'Sarat Pendaftaran'},{key:'C',label:'Hak & Kewajiban'},{key:'D',label:'Lokal'},{key:'E',label:'Oxigen'},{key:'F',label:'Layanan Dokter'}], sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'info_judul', type: 'text', name : 'Judul', filter : true, width : 'auto', grid : true, sort: {status: true, asc: 'asc'}},
                    {fieldFind:'', key : 'info_isi', type: 'text', name : 'Isi', filter : false, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'user_entry', type: 'text', name : 'User Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'date_entry', type: 'text', name : 'Date Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key: "date_edit",type: "text",name: "Date Edit",filter: false,width: "125px",grid: true, sort: {status: false, asc: '-'}},
                    {fieldFind:'', key: "user_edit",type: "text",name: "Entry",filter: false,width: "125px",grid: true, sort: {status: false, asc: '-'}},
                    {fieldFind:'', key : 'info_status', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}], sort: {status: false, asc: '-'}},
                ],
                find : [],
                order: ""
            },
            json_fields : {'Kode' : 'info_code','Nama' : 'info_isi'},
			editorConfig: {
				lang: YimoVueEditor.E.langs.en,
				i18next : window.i18next
			}
        };
    },
    methods : {
        findObjFind(key, val){
			// console.log(key, val);
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        setTextFind(txt,field){
            let fieldvalue = "";
            let ketemu = false;
            const self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.field ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        loadPage (pageNum){
            this.pages.halaman = pageNum;
            this.loadData();
        },
        setFilter(filter, operator){
            this.filters.find = filter;
            this.filters.operator = operator;
            this.loadData();
        },
        loadData(){
            const self = this;
            let vparams = {
                operator: self.filters.find.length > 0 ? self.filters.operator : "AND",
                kondisi: "",
                field: "",
                fieldvalue: "",
                limit: self.pages.batas,
                offset: eval(self.pages.batas + "*(" + self.pages.halaman + "-1)"),
                order: self.filters.order
            };

            if (self.filters.find.length > 0) {
                self.filters.find.map(function(value) {
                    vparams.kondisi += (vparams.kondisi !== "" ? ";" : "") + value.kondisi;
                    vparams.field += (vparams.field !== "" ? ";" : "") + value.field;
                    if (vparams.fieldvalue !== "null")
                        vparams.fieldvalue += (vparams.fieldvalue !== "" ? ";" : "") + value.fieldValue;
                    else
                        vparams.fieldvalue += (vparams.fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                });
            }
            self.apiPublicPost('/antrian/info/search',vparams,function(response){
                if(response.success==true){
                    self.selectedRow = -1;
                    self.recs = response.rec;
                    self.pages.ttlrec = parseFloat(response.ttlrec);
                    self.pages.ttlhalaman = Math.ceil(parseFloat(response.ttlrec)/self.pages.batas);
                    self.fieldRules = response.rules;
                }else{
                    console.log('Gagal');
                }
            }, function(err){
				console.log(err);
			});
        },
        setKlikRow(index, dtl){
            const self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dtl);
        },
        async showAdd(){
            const self = this;
            let key = await self.getCode();
            self.rec = { 
				info_code_old: "",
                info_code : "",
                info_judul : '',
                info_isi : '',
                info_jenis : '',
                info_status : 'T',
                info_default : 'T',
                user_entry: localStorage.username,
                date_entry: moment(String(new Date())).format("YYYY-MM-DD"),
                date_edit: moment(String(new Date())).format("YYYY-MM-DD"),
            };
            if(document.getElementById('id01').style.display!='block')
                document.getElementById('id01').style.display='block';
            setTimeout(function(){
                document.getElementById('info_judul').focus();
            },500);
        },
        showEdit(dt, idx){
            this.selectedRow = idx;
            this.stsAdd = false;
			dt["info_code_old"] = dt["info_code"];
            this.rec = Object.assign({}, dt);
            document.getElementById('id01').style.display='block';
        },
        saveData(event){
            const self = this;
            self.apiPublicPost('/antrian/info/save',self.rec,function(response){
                if(response.success==true){
                    self.$toast.success('Data Sudah tersimpan');
                    if (self.rec["info_code_old"]=="") {
						self.rec["info_code"]== response.kode;
                        self.recs.splice(0, 0, self.rec);
                    } else {
                        self.recs[self.selectedRow] = self.rec;
                    }
                    if(self.pages.autoadd=='T'){
                        self.showAdd();
                    }else{
                        document.getElementById('id01').style.display='none';
                    }
                }else{
                    self.$toast.error(response.msg);
                }
            }, function(err){
				console.log(err);
			});
        },
        showDelete(dt){
            const self = this;
            swal({
                title: "Yakin Ingin Menghapus Data ..!",
                text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ff0000",
                confirmButtonText: "Ya, Hapus Data",
                cancelButtonText: "Tidak, Batal Hapus",
                closeOnConfirm: true,
                closeOnCancel: true
            },function(isConfirm){
                if (isConfirm) {
                    let vparams = {info_code_old : dt.info_code};
                    self.apiPublicPost('/antrian/info/delete' ,vparams,function(response){
                        if(response.success==true){
                            self.$toast.success('Data Sudah terhapus');
                            self.$delete(self.recs,self.selectedRow);
                        }else{
                            self.$toast.error(response.msg);
                        }
                    });                    
                }
            });
        },
        showPrint() {
            this.$toast.info('Under Production');
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        setFilterTable(dt){
            if(!this.setFilterHeader(dt, this.filters)) {
                this.loadData();
            }
        },
		getJenis(kode){
			const self = this;
			const obj = self.findObj(self.filters.fields[1]["valueFind"], "key", kode);
			return obj==null ? "" : obj["label"];
		}
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        },
    },
    filters: {
        toRp (value) {
            return `${Intl.NumberFormat('de-DE').format(value)}`
        }
    },
    watch: {
        '$route': function(){
        }
    },
    mounted(){
        const self = this;
        this.pages = {halaman: 1,batas: this.perPage,ttlhalaman: 0,ttlrec: 0,autoadd: "T"};
        this.screenWidth = screen.width;
        this.screenHeight = window.innerHeight;
        this.eventGrid(self, 'grid', 'selectedRow', 'recs');
        this.getGridColumnmAutoWidth('grid',self.filters.fields, false);
        this.setFilterHeaders(this.filters);
        this.loadData();
		// console.log("Editor",YimoVueEditor.langs);
    }
};
</script>